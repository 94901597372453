import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getShowFeaturedCompany } from "../../selectors";
import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseFeaturedCompany = ({ company, children }) => {
	const showFeaturedCompany = useSelector(getShowFeaturedCompany);

	if (!showFeaturedCompany) return null;

	return (
		<CompanyContext.Provider
			value={{ company, companyIndex: 1, isFeaturedCompany: true }}
		>
			{children}
		</CompanyContext.Provider>
	);
};

BaseFeaturedCompany.propTypes = {
	company: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};
