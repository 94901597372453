import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { returnPaginationInformation } from "../../helpers";
import {
	getCategoryName,
	getIsTargetedPath,
	getVertical,
	sortedCompaniesSelector,
} from "../../selectors";
import { Pagination } from "../CompanyList/Pagination";
import { NoResults } from "../general/NoResults";
import { BaseCompanies } from "./BaseCompanies";
import { BaseFeaturedCompany } from "./BaseFeaturedCompany";

export const BaseCompanyList = ({ children, styles }) => {
	const location = useLocation();
	const vertical = useSelector(getVertical);
	const isTargetedPath = useSelector(getIsTargetedPath);
	const companies = useSelector(sortedCompaniesSelector);
	const categoryName = useSelector(getCategoryName);
	const {
		count,
		partialQueryObjectForPaginationClicks,
		isInvalidQuery,
		page,
		redirectLocationSearch,
	} = returnPaginationInformation(location, vertical, isTargetedPath);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, [page]);

	if (isInvalidQuery) {
		return <Navigate to={`${location.pathname}?${redirectLocationSearch}`} />;
	}

	if (companies.length === 0) {
		return <NoResults categoryName={categoryName} />;
	}

	return (
		<div className={styles.mainContainer}>
			<div id="companyList" className={styles.companyListContainer}>
				<BaseCompanies
					companies={companies}
					sliceStart={(page - 1) * count}
					count={count}
				>
					{children}
				</BaseCompanies>
				{companies.length > count && (
					<Pagination
						page={page}
						pageCount={Math.ceil(companies.length / count)}
						count={count}
						partialQueryObjectForPaginationClicks={
							partialQueryObjectForPaginationClicks
						}
						className={styles.paginationContainer}
						pageLinkClassName={styles.pageLink}
						previousLinkClassName={`${styles.previousLink} ${styles.pageLink}`}
						nextLinkClassName={`${styles.nextLink} ${styles.pageLink}`}
						activeLinkClassName={styles.activeLink}
						disabledLinkClassName={styles.disabledLink}
					/>
				)}
				<BaseFeaturedCompany company={companies[0]}>
					{children}
				</BaseFeaturedCompany>
			</div>
		</div>
	);
};

BaseCompanyList.propTypes = {
	children: PropTypes.node,
	styles: PropTypes.object,
};
