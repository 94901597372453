import { Icon } from "../../components/BaseComponents/Icon";

export const LargePhoneIcon = (props) => (
	<Icon fill="none" viewBox="0 0 48 48" {...props}>
		<path
			d="M26.15 4.49A1.7 1.7 0 0 1 27.33 4 16.68 16.68 0 0 1 44 20.67a1.67 1.67 0 0 1-3.33 0A13.35 13.35 0 0 0 27.33 7.33a1.67 1.67 0 0 1-1.18-2.84Zm13.18 28.97-9.31-4a1.67 1.67 0 0 0-1.84.35l-3.89 3.83a31.58 31.58 0 0 1-5.62-4.3 31.92 31.92 0 0 1-4.32-5.62l3.84-3.89a1.67 1.67 0 0 0 .34-1.83l-4-9.32a1.67 1.67 0 0 0-1.95-.95L5.25 9.67A1.67 1.67 0 0 0 4 11.33a35.24 35.24 0 0 0 9.98 22.7A35.23 35.23 0 0 0 36.68 44a1.67 1.67 0 0 0 1.66-1.25l1.94-7.33a1.67 1.67 0 0 0-.95-1.96ZM34 20.66a1.67 1.67 0 1 0 3.33 0 10.01 10.01 0 0 0-10-10 1.67 1.67 0 1 0 0 3.34A6.67 6.67 0 0 1 34 20.67Z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</Icon>
);
