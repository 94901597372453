import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { ModalOverlay } from "react-aria-components";
import { useSelector } from "react-redux";

import {
	getTargetedSlug,
	getVertical,
	sortedCompaniesSelector,
} from "../../selectors";
import { CompanyContext } from "../CompanyList/CompanyContext";

const DELAY_MS = 5000;

export const BaseExitModalProvider = ({
	vertical,
	topCompanySlug,
	delayMs = DELAY_MS,
	children,
	...props
}) => {
	const currentVertical = useSelector(getVertical);
	const targetedSlug = useSelector(getTargetedSlug);

	if (targetedSlug == null) return null;
	if (vertical !== currentVertical) return null;

	return (
		<ExitModalProvider topCompanySlug={topCompanySlug}>
			<ExitModalOverlay delayMs={delayMs} {...props}>
				{children}
			</ExitModalOverlay>
		</ExitModalProvider>
	);
};

BaseExitModalProvider.propTypes = {
	vertical: PropTypes.string.isRequired,
	topCompanySlug: PropTypes.string.isRequired,
	delayMs: PropTypes.number,
	children: PropTypes.node.isRequired,
};

export const ExitModalProvider = ({ topCompanySlug, children }) => {
	const companies = useSelector(sortedCompaniesSelector);

	const company = companies.find(
		(company) => company.fields.companySlug === topCompanySlug,
	);

	if (!company) return null;

	return (
		<CompanyContext.Provider
			key={path(["fields", "companyName"], company)}
			value={{ company: company, companyIndex: 1 }}
		>
			{children}
		</CompanyContext.Provider>
	);
};

ExitModalProvider.propTypes = {
	topCompanySlug: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const ExitModalOverlay = ({ delayMs, children, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const hasOpened = useRef(false);

	useEffect(() => {
		if (!hasOpened.current) {
			const timer = setTimeout(() => {
				setIsOpen(true);
				hasOpened.current = true;
			}, delayMs);

			return () => clearTimeout(timer);
		}
	}, []);

	return (
		<ModalOverlay
			isOpen={isOpen}
			onOpenChange={setIsOpen}
			isKeyboardDismissDisabled={true}
			data-testid="exit-modal"
			{...props}
		>
			{children}
		</ModalOverlay>
	);
};

ExitModalOverlay.propTypes = {
	delayMs: PropTypes.number,
	children: PropTypes.node.isRequired,
};
