import { Icon } from "../../components/BaseComponents/Icon";

export const CouponDiscountIcon = (props) => (
	<Icon fill="none" {...props}>
		<path
			fill="currentColor"
			d="m20.34 12-8.67-8.67c-.21-.21-.5-.33-.8-.33H4.14A1.13 1.13 0 0 0 3 4.13v6.75c0 .3.12.58.33.8L12 20.33a2.25 2.25 0 0 0 3.18 0l5.16-5.16a2.25 2.25 0 0 0 0-3.18ZM8.63 10.31a1.69 1.69 0 1 1 0-3.37 1.69 1.69 0 0 1 0 3.37Z"
		/>
	</Icon>
);
