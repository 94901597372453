import { useEffect } from "react";
import PropTypes from "prop-types";
import { path } from "ramda";

import { replacePhoneNumbers } from "../../helpers";
import { useTargetedPageCompanies } from "../../hooks/useTargetedPageCompanies";
import { CompanyContext } from "../CompanyList/CompanyContext";

export const BaseCompanies = ({ companies, sliceStart, count, children }) => {
	const companiesToRender = companies.slice(sliceStart, sliceStart + count);
	const targetedCompanies = useTargetedPageCompanies(companiesToRender);

	useEffect(() => {
		replacePhoneNumbers();
	}, [targetedCompanies]);

	return (
		<>
			{targetedCompanies.map((company, idx) => (
				<CompanyContext.Provider
					key={path(["fields", "companyName"], company)}
					value={{
						company,
						companyIndex: idx + sliceStart + 1,
						isFeaturedCompany: false,
					}}
				>
					{children}
				</CompanyContext.Provider>
			))}
		</>
	);
};

BaseCompanies.propTypes = {
	sliceStart: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	companies: PropTypes.array.isRequired,
	children: PropTypes.node.isRequired,
};
