import { Icon } from "../../components/BaseComponents/Icon";

export const ExitModalBestPriceIcon = (props) => (
	<Icon fill="none" viewBox="0 0 40 40" {...props}>
		<path
			fill="url(#ExitModalBestPriceIcon)"
			d="M16.23 3.42a5.62 5.62 0 0 1 7.55 0 5.62 5.62 0 0 0 3.99 1.46 5.62 5.62 0 0 1 5.78 4.85c.2 1.45.96 2.77 2.12 3.68a5.62 5.62 0 0 1 1.31 7.43 5.62 5.62 0 0 0-.73 4.18 5.62 5.62 0 0 1-3.78 6.54 5.62 5.62 0 0 0-3.25 2.73 5.62 5.62 0 0 1-7.1 2.58 5.62 5.62 0 0 0-4.24 0 5.62 5.62 0 0 1-7.1-2.58 5.62 5.62 0 0 0-3.25-2.73 5.62 5.62 0 0 1-3.77-6.54 5.62 5.62 0 0 0-.74-4.18 5.62 5.62 0 0 1 1.31-7.43 5.62 5.62 0 0 0 2.13-3.68 5.62 5.62 0 0 1 5.78-4.85c1.47.05 2.9-.47 3.99-1.46Z"
		/>
		<path
			fill="#fff"
			d="M25.72 23.76a4 4 0 0 1-1.17 2.94 5.34 5.34 0 0 1-3.23 1.4c-.08.03-.12.07-.12.14l.03 1.49c0 .07-.04.13-.1.18a.23.23 0 0 1-.19.09H19.1a.3.3 0 0 1-.22-.1.24.24 0 0 1-.07-.17l.03-1.52c0-.06-.04-.1-.12-.13a5.79 5.79 0 0 1-3.26-1.47 4.04 4.04 0 0 1-1.16-2.97v-.56c0-.08.02-.14.07-.18a.3.3 0 0 1 .21-.1h2c.08 0 .14.04.19.1.06.04.1.1.1.18v.43c0 .69.3 1.27.92 1.74a4.2 4.2 0 0 0 2.52.68c.93 0 1.63-.2 2.09-.6.48-.38.71-.89.71-1.5 0-.43-.12-.78-.38-1.07-.25-.3-.62-.57-1.11-.8-.48-.23-1.2-.54-2.14-.92a27.2 27.2 0 0 1-2.6-1.1 4.88 4.88 0 0 1-1.66-1.41 3.68 3.68 0 0 1-.66-2.24c0-1.15.36-2.09 1.1-2.83a5.18 5.18 0 0 1 3.01-1.38c.08-.03.12-.07.12-.13l-.05-1.68c0-.07.03-.13.07-.18a.3.3 0 0 1 .22-.09h1.85c.08 0 .14.03.19.09.06.05.1.1.1.18l-.05 1.7c0 .06.04.1.12.13a5.4 5.4 0 0 1 3.18 1.54c.8.82 1.19 1.82 1.19 3.01v.39c0 .07-.03.14-.1.2a.26.26 0 0 1-.19.07H23.3a.36.36 0 0 1-.21-.07.32.32 0 0 1-.07-.2v-.25c0-.71-.3-1.3-.88-1.79a3.5 3.5 0 0 0-2.38-.75c-.84 0-1.49.18-1.95.52-.45.35-.68.84-.68 1.47 0 .46.11.83.35 1.11.26.29.62.55 1.1.8.49.22 1.23.52 2.23.88 1.08.4 1.93.77 2.56 1.1.65.34 1.2.8 1.67 1.4.46.6.69 1.34.69 2.23Z"
		/>
		<defs>
			<linearGradient
				id="ExitModalBestPriceIcon"
				x1="20"
				x2="20"
				y1="0"
				y2="40"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#6AD084" />
				<stop offset="1" stopColor="#3CB34B" />
			</linearGradient>
		</defs>
	</Icon>
);
