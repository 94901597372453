import { Icon } from "../../components/BaseComponents/Icon";

export const PhoneIcon = (props) => (
	<Icon fill="none" viewBox="0 0 16 16" {...props}>
		<path
			fill="currentColor"
			d="M15.12 11c-1.1 0-2.15-.17-3.14-.5a.87.87 0 0 0-.9.22l-1.4 1.75A13.5 13.5 0 0 1 3.57 6.4L5.3 4.92a.9.9 0 0 0 .21-.9 9.91 9.91 0 0 1-.5-3.14c0-.48-.4-.88-.88-.88H1.06C.58 0 0 .21 0 .88 0 9.14 6.87 16 15.12 16c.63 0 .88-.56.88-1.05v-3.07c0-.48-.4-.88-.88-.88Z"
		/>
	</Icon>
);
