import { useContext } from "react";

import { CheckIcon } from "../../../client/assets/icons/common/CheckIcon";
import { RightArrowIcon } from "../../../client/assets/icons/common/RightArrowIcon";
import { BaseBenefitsList } from "../../../components/BaseComponents/BaseBenefitsList";
import { BaseCompanyLogo } from "../../../components/BaseComponents/BaseCompanyLogo";
import BaseCompanyName from "../../../components/BaseComponents/BaseCompanyName";
import { BaseCoupon } from "../../../components/BaseComponents/BaseCoupon";
import { BaseDotNumber } from "../../../components/BaseComponents/BaseDotNumber";
import { BaseFeaturedCompanyHeader } from "../../../components/BaseComponents/BaseFeaturedCompanyHeader";
import BaseGridRatingHorizontal from "../../../components/BaseComponents/BaseGridRatingHorizontal";
import BaseLinkWrapper from "../../../components/BaseComponents/BaseLinkWrapper";
import BaseServiceFlag from "../../../components/BaseComponents/BaseServiceFlag";
import { BaseSocialProof } from "../../../components/BaseComponents/BaseSocialProof";
import { BaseVisitLink } from "../../../components/BaseComponents/BaseVisitLink";
import { CompanyContext } from "../../../components/CompanyList/CompanyContext";
import { CouponDiscountIcon } from "../../assets/CouponDiscountIcon";
import { PhoneIconLink } from "./PhoneIconLink";
import { getStyles } from "./styles";

export const MovingCompanies = () => {
	const { companyIndex, isFeaturedCompany } = useContext(CompanyContext);
	const styles = getStyles({ isFeaturedCompany });

	return (
		<div className="tw-rounded-[12px] tw-shadow-[0px_4px_24px_-4px_rgba(90,97,107,0.07),0px_2px_6px_-2px_rgba(90,97,107,0.07),0px_1px_3px_-1px_rgba(90,97,107,0.07),0px_0px_1px_0px_rgba(90,97,107,0.25)] tw-transition hover:tw-shadow-[0px_8px_32px_0px_rgba(0,0,0,0.08),0px_2px_20px_0px_rgba(0,0,0,0.04)]">
			<div
				className={`tw-rounded-[12px] tw-bg-white ${
					isFeaturedCompany
						? "tw-ring-2 tw-ring-[#16A34A]"
						: "tw-ring-[0.5px] tw-ring-[rgba(22,34,51,0.07)]"
				}`}
			>
				<BaseFeaturedCompanyHeader {...styles.featuredCompanyHeader} />
				<BaseLinkWrapper>
					<BaseCoupon {...styles.coupon} icon={<CouponDiscountIcon />} />
				</BaseLinkWrapper>
				<div className="tw-relative">
					<BaseServiceFlag classNames={styles.serviceFlag} />
					<div className="tw-mx-auto tw-flex tw-max-w-[400px] tw-flex-col tw-gap-4 tw-p-4 md:tw-max-w-none md:tw-flex-row md:tw-gap-0 lg:tw-px-6 lg:tw-pb-6">
						<div className="tw-relative tw-flex tw-shrink-0 tw-flex-col tw-items-center tw-justify-center tw-pb-4 md:tw-py-0 md:tw-pr-1 lg:tw-w-[204px] lg:tw-pr-3 xl:tw-w-[284px]">
							<BaseLinkWrapper className="tw-flex tw-size-full tw-flex-col tw-items-center tw-justify-center">
								<BaseCompanyLogo
									classNames={styles.companyLogo}
									width={160}
									height={160}
								/>
							</BaseLinkWrapper>
							<BaseDotNumber className="tw-inline-flex tw-items-center tw-justify-center tw-gap-2.5 tw-rounded tw-border tw-border-solid tw-border-[#E1E2E5] tw-bg-white tw-px-2 tw-py-1 tw-text-xs tw-font-semibold tw-leading-4 tw-text-[#4A5059] md:tw-absolute md:tw-bottom-0 md:tw-rounded-lg md:tw-px-2.5 md:tw-py-[7px] md:tw-text-sm md:tw-leading-normal" />
						</div>
						<div className="tw-flex tw-grow tw-flex-col md:tw-max-w-[296px] md:tw-px-1 lg:tw-max-w-none lg:tw-px-3">
							<BaseLinkWrapper>
								<div className="tw-flex tw-w-full tw-flex-col">
									<BaseCompanyName className="tw-text-lg tw-font-bold tw-leading-6 tw-tracking-[-0.18px] tw-text-[#131D2B] sm:tw-text-xl sm:tw-leading-8 sm:tw-tracking-[-0.2px]" />
									<BaseGridRatingHorizontal classNames={styles.rating} />
									<BaseBenefitsList
										{...styles.benefitsList}
										icon={
											<CheckIcon className="tw-text-base tw-text-[#16A34A] sm:tw-mt-[5px]" />
										}
									/>
								</div>
							</BaseLinkWrapper>
						</div>
						<div className="tw-flex tw-shrink-0 md:tw-w-[212px] md:tw-pl-1 lg:tw-pl-3">
							<div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-4 md:tw-gap-3.5">
								<div className="tw-flex tw-w-full tw-flex-wrap tw-gap-3 md:tw-gap-4">
									<PhoneIconLink />
									<BaseLinkWrapper className="tw-flex tw-flex-1 md:tw-w-full">
										<BaseVisitLink
											{...styles.visitLink}
											icon={<RightArrowIcon />}
										>
											Get Free Quote
										</BaseVisitLink>
									</BaseLinkWrapper>
								</div>
								{companyIndex === 1 && (
									<BaseSocialProof {...styles.socialProof} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
