export const getStyles = ({ isFeaturedCompany }) => ({
	featuredCompanyHeader: {
		wrapper:
			"tw-flex tw-justify-center tw-bg-[#16A34A] tw-text-white tw-rounded-t-[12px] tw-py-3",
		contentWrapper:
			"tw-text-center tw-text-base tw-font-bold tw-leading-6 tw-tracking-[-0.16px]",
	},
	coupon: {
		wrapper: "tw-group tw-p-2 tw-cursor-pointer",
		contentWrapper:
			"tw-flex tw-bg-[#F0FDF4] tw-text-[#005541] tw-rounded tw-w-full tw-p-2 tw-gap-2 tw-justify-center tw-items-center",
		contentIcon: "tw-inline-flex tw-text-2xl",
		content:
			"tw-text-center tw-text-sm tw-font-bold tw-leading-5 group-hover:tw-underline md:tw-text-base md:tw-leading-6 md:tw-tracking-[-0.16px]",
	},
	serviceFlag: {
		wrapper: "",
		flag: `tw-absolute tw-left-4 tw-top-4 tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-current tw-bg-white md:tw--translate-y-1/2 md:tw--left-4 md:tw-top-2/4 ${
			isFeaturedCompany ? "tw-text-[#16A34A]" : "tw-text-[#131D2B]"
		}`,
		number: "tw-text-current tw-text-base tw-font-medium tw-leading-6",
		text: "tw-absolute tw-top-4 tw-left-0 tw-text-white tw-text-xs tw-bg-[#16A34A] tw-font-medium tw-py-1 tw-pl-2 tw-pr-[16px] tw-leading-4 [clip-path:polygon(100%_0,calc(100%_-_10px)_50%,100%_100%,0_100%,0_0)] md:tw-top-6",
	},
	companyLogo: { picture: "", image: "" },
	rating: {
		wrapper: "tw-mt-2 sm:tw-mt-1 tw-flex tw-items-center tw-gap-2",
		score:
			"tw-text-lg tw-leading-6 tw-font-bold tw-text-[#131D2B] sm:tw-text-xl sm:tw-leading-8",
		stars: "tw-flex tw-items-center tw-gap-2",
		starColor: "!tw-text-[#FB923C] !tw-text-base sm:!tw-text-lg",
		count:
			"tw-text-sm tw-font-semibold tw-leading-6 tw-text-[#131D2B] sm:tw-text-base",
	},
	benefitsList: {
		wrapper: "tw-mt-4",
		list: "tw-flex tw-flex-col tw-gap-2 tw-justify-center",
		listItem: "tw-flex tw-text-[#1E293B] tw-text-sm tw-leading-6 tw-gap-2",
	},
	socialProof: {
		wrapper:
			"tw-relative tw-w-full tw-flex tw-pt-2.5 tw-justify-center tw-border-t tw-border-[rgba(88,102,126,0.10)] md:tw-max-w-fit md:tw-bg-white md:tw-rounded-lg md:tw-p-2 md:[filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] md:after:tw-absolute md:after:tw-right-[calc(50%-6px)] md:after:tw-w-2.5 md:after:tw-h-2.5 md:after:tw-translate-x-0 md:after:tw--translate-y-2/4 md:after:tw-rotate-45 md:after:tw-bg-white md:after:tw-overflow-hidden md:after:tw-pointer-events-none md:after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#FF8526] tw-text-xl md:tw-text-[32px]",
		content:
			"tw-leading-4 tw-font-semibold tw-text-xs tw-text-left tw-text-[#4A5059] md:tw-max-w-[110px] ",
	},
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-group tw-justify-center tw-py-3 tw-px-2 tw-bg-[#16A34A] tw-rounded-[12px] tw-transition tw-duration-300 tw-gap-1.5 tw-cursor-pointer hover:tw-bg-[#15803c]",
		contentWrapper:
			"tw-text-center tw-text-white tw-font-semibold tw-leading-4 tw-text-sm md:tw-text-base md:tw-leading-6 md:tracking-[-0.16px] tw-whitespace-nowrap",
		iconClass:
			"tw-hidden tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-inline-flex",
	},
});
