import { useContext } from "react";
import { getCompanyPhoneNumber } from "@/selectors";
import { ThemeContext } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import BasePhoneLink from "../../../components/BaseComponents/BasePhoneLink";
import { CompanyContext } from "../../../components/CompanyList/CompanyContext";
import { twMerge } from "../../../helpers/twMerge";
import { LargePhoneIcon } from "../../assets/LargePhoneIcon";
import { PhoneIcon } from "../../assets/PhoneIcon";

const DEFAULT_CTA_TEXT = "Call Now";

export const PhoneIconLink = ({ className }) => {
	const { company } = useContext(CompanyContext);
	const { phoneCtaText = DEFAULT_CTA_TEXT } = useContext(ThemeContext);

	const phoneNumber = useSelector((state) =>
		getCompanyPhoneNumber(state, { company }),
	);

	return (
		<BasePhoneLink
			company={company}
			className={twMerge("tw-flex tw-flex-1", className)}
		>
			<div className="tw-relative tw-flex tw-w-full tw-justify-center tw-gap-1.5 tw-rounded-[12px] tw-px-2 tw-py-3 tw-ring-2 tw-ring-inset tw-ring-[#16A34A] md:tw-gap-2 md:tw-p-0 md:tw-ring-0">
				<div className="tw-inline-flex tw-text-[#16A34A]">
					<LargePhoneIcon className="tw-hidden tw-text-5xl md:tw-inline-block" />
					<PhoneIcon className="tw-inline-block tw-text-base md:tw-hidden" />
				</div>
				<div className="tw-flex tw-flex-col tw-gap-0.5">
					<div className="tw-whitespace-nowrap tw-text-sm tw-font-semibold tw-leading-4 tw-text-[#16A34A] md:tw-text-base md:tw-leading-5 md:tw-text-[#131D2B]">
						{phoneCtaText}
					</div>
					<div className="tw-hidden tw-text-lg tw-font-bold tw-leading-[26px] tw-tracking-[-0.2px] tw-text-[#16A34A] md:tw-flex md:tw-text-xl">
						{phoneNumber}
					</div>
				</div>
			</div>
		</BasePhoneLink>
	);
};

PhoneIconLink.propTypes = {
	className: PropTypes.string,
};
