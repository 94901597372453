import { createRoot, hydrateRoot } from "react-dom/client";

import { ClientApp } from "../../components/UIComponents/ClientApp";
import { App } from "./App";

const MainClient = () => (
	<ClientApp>
		<App />
	</ClientApp>
);

const container = document.getElementById("app");
if (import.meta.env.DEV) {
	const root = createRoot(container);
	root.render(<MainClient />);
} else {
	hydrateRoot(container, <MainClient />);
}
