import { useContext } from "react";

import { CompanyContext } from "../CompanyList/CompanyContext";
import BaseLinkWrapper from "./BaseLinkWrapper";

export const BaseDotNumber = (props) => {
	const { company } = useContext(CompanyContext);
	const dotNumber = company.fields.dotNumber;
	if (!dotNumber) return null;

	return (
		<BaseLinkWrapper {...props}>{`US DOT # ${dotNumber}`}</BaseLinkWrapper>
	);
};
